<template>
  <section>
    <div class="row">
      <div class="col-12">
        <div class="card border rounded">
          <div class="card-body">
            <b-row>
              <b-col lg="11">
                <h3 class="my-3 mx-3 text-center">Saque realizado!</h3>
              </b-col>
              <b-col lg="1" class="py-3 d-none d-lg-block">
                <router-link :to="{ name: 'home' }" class="link">
                  <h3><i class="mdi mdi-arrow-left"></i></h3>
                </router-link>
              </b-col>
            </b-row>
            <hr>
            <div class="text-center text-muted">
              <h4>Logo logo o dinheiro cairá na sua conta. Basta aguardar!</h4>
              <hr>
              <b><i class="fa fa-warning" /> ATENÇÃO:</b> não é possível realizar transferências idênticas (mesmo valor para a mesma pessoa) no mesmo dia.
              Caso essa seja uma transação duplicada de outra já realizada hoje, o dinheiro não será movimentado da sua conta.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PlgUsersApi from "../../apis/PlgUsersApi.vue";
import PlgBankServicesApi from "../../apis/PlgBankServicesApi.vue";

export default {
  name: "PlgPixWithdraw",
  data() {
    return {
      isLoading: false,
      user: {},
      amount: 0,
    }
  },
  mixins: [
    PlgUsersApi,
    PlgBankServicesApi,
  ],
  methods: {
  },
  async mounted() {
    const response = await this.getCurrentUser();
    this.user = response.data;
  }
};
</script>

<style scoped>
.link {
  color: black;
}
</style>